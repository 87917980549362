.autocomplete-wrapper {
    margin-bottom: 30px;
    padding: 0 15px;
}
.button-wrapper button{
    background-color: rgb(255,237,0);
    color: black;
}
.endPage-title{
    margin-bottom: 40px;
}
.endPage-el {
    margin-bottom: 10px;
}
.endPage-el__title{
    font-weight: bold;
    font-size: 18px;
}
.page-title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 30px;
}
.page-buttons {
    display: flex;
    flex-direction: column;
    padding: 0 14px;
    justify-content: space-evenly;
    margin-top: 15px;
}
.page-buttons button {
    margin-bottom: 15px;
}
.page-two-col{
    display: flex;
    padding: 0 20px;
}
.page-two-col__left {
    margin-right: 10px;
}
.page-two-col__left > *{
    margin-bottom: 5px!important;
}
.page-two-col__right > *{
    margin-bottom: 5px!important;
}
.page-two-col img {
    width: 40px
}
.page-two-col .MuiInputBase-root {
    padding-left: 5px;
}
.page-choose {
    padding: 0 15px;
}
.page-choose .MuiAutocomplete-root {
    margin-bottom: 15px;
}
.page-checklist {
    padding: 0 10px;
}
.page-arrows {
    position: relative;
    padding: 0 15px;
    margin-top: 15px;
}
.page-arrows .left{
    position: absolute;
    left: 15px;
}
.page-arrows .right{
    position: absolute;
    right: 15px;
}

button.MuiButtonBase-root {
    background-color: rgb(255,237,0) !important;
    color: black !important;
}
.Mui-checked {
    color: rgb(255,237,0) !important;
}